<template>
  <div class="row">
    <div class="col-12">
      <operator-datatable/>
    </div>
  </div>
</template>

<script>
  import OperatorDatatable from "@/pages/CallCenter/datatables/OperatorDatatable";

  export default {
    name: "IndexOperatorPage",
    components: {OperatorDatatable},
  }
</script>

<style scoped>

</style>
