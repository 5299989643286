<template>
  <octo-table
    :action="endpoints.DATATABLES.operators"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="resetFilters"
  >
    <template v-slot:username="data">
      {{ users[data.row.id] | optional('lastname') }}
      {{ users[data.row.id] | optional('firstname') }}
    </template>

    <template v-slot:campaigns="data">
      <div
        v-for="(campaign, index) in data.row.campaigns"
        v-bind:key="`campaign-${index}`"
        class="text-uppercase small"
      >
        {{ campaign.name }}
      </div>
    </template>

    <template v-slot:callId="data">
      {{ data.row.operator_number }}
    </template>

    <template v-slot:salary="data">
      {{ data.row.salary | euro }}
    </template>

    <template v-slot:profile="data">
      <div class="text-uppercase small">
        {{ data.row | optional('profile') }}
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <el-tooltip content="Settings" :open-delay="300" placement="top">
          <base-button
            link
            icon
            class="mx-1"
            size="sm"
            @click="$router.push({name: 'users.operators.show', params: {id : data.row.id }})"
          >
            <octo-icon icon="right-arrow"/>
          </base-button>
        </el-tooltip>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {Option, Select} from "element-ui";
import {mapGetters} from "vuex";

export default {
  name: "OperatorDatatable",
  components: {
    Badge,
    OctoIcon,
    OctoTable,
    [Option.name]: Option,
    [Select.name]: Select
  },
  data() {
    return {
      name: 'OperatorDatatable',
      endpoints: endpoints,
      filters: {
        roles: ['call-center-operator'],
        campaignIds: []
      },
      fields: [
        {prop: 'username', label: 'username', slot: true, width: 300},
        {prop: 'campaigns', label: 'campaigns', width: 250, slot: true, align: 'center'},
        {prop: 'operator_number', label: 'callId', width: 100, align: 'center'},
        {prop: 'salary', label: 'salary', slot: true, width: 150},
        {prop: 'profile', label: 'profile', slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', width: 100, slot: true},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name)
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      users: 'common/users'
    }),
  },
  methods: {
    resetFilters: function () {
      this.filters = {
        roles: ['call-center-operator'],
        campaignIds: []
      };
    }
  }
}
</script>

<style scoped>

</style>
